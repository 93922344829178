import { makeQuery } from "../../Common/util.helper";
import Http from "../../library/http.library";
import { httpMethods } from "../../library/enum";
import axios from "axios";

export const createCashback = async (tokens: any, data: any) => {
  return new Promise(async (resolve, reject) => {
    let apiUrl = process.env.REACT_APP_URL + `/order/cashback/create`;
    try {
      let result = await axios.post(apiUrl, data, {
        headers: {
          "Content-Type": "multipart/form-data",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "DELETE, POST, GET, OPTIONS",
          Authorization: `Bearer ${tokens.idToken.jwtToken}`,
        },
      });
      resolve(result);
    } catch (error) {
      reject(error);
    }
  });
};

const getCashbackMasterList = async (query: any, page: number, tokens: any) => {
  return await Http.setMethod(httpMethods.get)
    .setUrl(
      `/order/getAllCashbackDetails?isPaginated=true&page=${page}${query}`
    )
    .setToken(tokens.idToken.jwtToken)
    .request();
};
const viewCashback = async (tokens: any, corId?: any) => {
  return await Http.setMethod(httpMethods.get)
    .setUrl(`/order/getParticularCashbackUserDetail/${corId}`)
    .setToken(tokens.idToken.jwtToken)
    .request();
};

const downloadSample = async (tokens: any) => {
  return await Http.setMethod(httpMethods.get)
    .setUrl(`/order/cashback/download-sample`)
    .setToken(tokens.idToken.jwtToken)
    .request();
};

const CashbackService = {
  getCashbackMasterList,
  viewCashback,
  createCashback,
  downloadSample,
};

export default CashbackService;
