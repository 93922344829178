import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { CSVLink } from "react-csv";

import Layout from "../Layout/Layout";
import UserTable from "./UserTable";
import Loader from "../../Components-ui/Loader";
import { useAuthStore } from "../../Store/AuthStore";
import { toast } from "react-toastify";
import CashbackService from "./Cashback.service";

const CashbackView: React.FC = () => {
  const [Loading, setLoading] = useState<any>(false);
  const { logout, permissions, tokens } = useAuthStore();

  //-----Get bulk users
  const fileName = "users-detail";
  const [userData, setUserData] = useState<any>([]);
  const [csvData, setCsvData] = useState([]);
  const [isCompleted, setIsCompleted] = useState<boolean>(false);
  const { id } = useParams<{ id?: string }>();

  const getUserData = async (id: any) => {
    setLoading(true);
    await CashbackService.viewCashback(tokens, id)
      .then((response: any) => {
        setIsCompleted(response.data.data.userData[0].isCompleted);
        const data = response.data.data.userData;
        const headers = [
          "cashbackId",
          "Name",
          "Mobile",
          "Email",
          "createdAt",
          "isCompleted",
          "walletUpdated",
          "Wallet_Error",
          "steps",
          "Step_Status",
        ];
        const aliases: any = {
          cashbackId: "cashbackId",
          Name: "displayName",
          Mobile: "mobile",
          Email: "email",
          createdAt: "createdAt",
          isCompleted: "isCompleted",
          walletUpdated: "walletUpdated",
          Wallet_Error: "walletError",
          steps: "steps",
          Step_Status: "stepStatus",
        };

        const csvData: any = [
          headers,
          ...data.map((row: any) =>
            headers.map((header) =>
              typeof row[aliases[header]] === "boolean"
                ? row[aliases[header]]
                  ? "Yes"
                  : "No"
                : row[aliases[header]]
            )
          ),
        ];
      
        setUserData(response.data.data.userData);
        setCsvData(csvData);
        setLoading(false);
      })
      .catch((err: any) => {
        setLoading(false);
        toast.error(err);
      });
  };
  useEffect(() => {
    getUserData(id);
  }, []);

  return (
    <Layout type={"PaymentModule"}>
      {Loading ? (
        <Loader />
      ) : (
        <>
          <div className="flex justify-between items-center mb-5">
            <h1 className="text-2xl font-bold tracking-tight">{"Users"}</h1>

            <div>
              <button className="text-white  bg-purple-950 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none  ease-linear transition-all duration-150 rounded-md mr-2">
                <CSVLink
                  data={csvData}
                  filename={fileName}
                  style={{ textDecoration: "none" }}
                >
                  {"Export"}
                </CSVLink>
              </button>
            </div>
          </div>

          <div className="border bg-white rounded border-gray-200 shadow">
            <div className="container">
              <UserTable
                userData={userData}
                Loading={Loading}
                // isCompleted={isCompleted}
              />
            </div>
          </div>
        </>
      )}
    </Layout>
  );
};

export default CashbackView;
