import React, { Fragment } from "react";
import { Route } from "react-router-dom";
import ListOfCustomers from "../Customer/Components/ListOfCustomers";
import CashbackList from "./CashbackList";
import CashbackView from "./CashbackView";
// import ListOfCustomers from "../Customer/Components/ListOfCustomers";

const CashbackRoutes: React.FC = () => {
  return (
    <Fragment>
      {" "}
      <Route path="/cashback" exact component={CashbackList} />
      <Route path="/cashback-view/:id" exact component={CashbackView} />
    </Fragment>
  );
};

export default CashbackRoutes;
