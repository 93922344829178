import React, { useState, useEffect } from "react";

import { useHistory } from "react-router-dom";

import { toast, ToastContainer } from "react-toastify";

import moment from "moment";

import Datetime from "react-datetime";
import Layout from "../Layout/Layout";
import CashbackService from "./Cashback.service";
import { useAuthStore } from "../../Store/AuthStore";
import CustomerService from "../Customer/Services/Customer.service";
import { Pagination } from "../../Components-ui/Pagination";
import { makeQuery } from "../../Common/util.helper";
import AddGiftModal from "../CorporateGift/AddGift";
import CreateCashback from "./CreateCashback";
import HasAnyAccess from "../../Components-ui/hasAnyAccess";

const CashbackList: React.FC = () => {
  const { logout, permissions, tokens } = useAuthStore();
  const [openModal, setOpenModal] = useState(false);
  const [users, setUsers] = useState<any>({});
  const [meta, setMetaData] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const [giftlist, setgiftlist] = useState([]);

  const [Filter, setFilter] = useState<any>({
    b2bSenderId: "",
    metal: "",
    corId: "",
    metalType: "",
    startDate: "",
    endDate: "",
    partnerId: "",
  });
  const history = useHistory();

  const closeModal = () => {
    setOpenModal(false);
  };

  const handleSubmit = async (page: number, isReset: boolean = false) => {
    let cloneFilterData: any;
    setLoading(true);

    cloneFilterData = !isReset
      ? { ...Filter }
      : {
          b2bSenderId: "",
          metal: "",
          corId: "",
          metalType: "",
          startDate: "",
          endDate: "",
          partnerId: "",
        };

    try {
      const query = makeQuery(cloneFilterData);
      setLoading(true);
      const res: any = await CashbackService.getCashbackMasterList(
        query,
        page,
        tokens
      );
      if (res.status === 200) {
        setMetaData({
          current: res.data.meta.page,
          pages: res.data.meta.totalPages,
        });
        setUsers(res ? res.data.data.result : []);
        setLoading(false);
        setgiftlist(res ? res.data.data.result : []);
        setLoading(false);
      } else {
        setLoading(false);
        toast.error("Something Went Wrong");
      }
    } catch (err) {
      setLoading(false);
      toast.error("Something Went Wrong");
    }
    // console.log(data ? data.data.data.result : [])
  };
  const viewFile = (companyId: any) => {
    history.push(`/cashback-view/${companyId}`);
  };
  const today = new Date();

  const downloadSample = async () => {
    setLoading(true);
    await CashbackService.downloadSample(tokens)
      .then((response: any) => {
        window.open(response.data.data.payload.data);
        setLoading(false);
        toast.success("Sample Downloaded Successfully");
      })
      .catch((err: any) => {
        setLoading(false);
        toast.success("Something Went Wrong");
      });
  };

  useEffect(() => {
    handleSubmit(0);
  }, []);

  return (
    <Layout type={"UserModule"}>
      <ToastContainer />
      <div className="flex justify-between items-center mb-5">
        <h1 className="text-2xl font-bold tracking-tight">{"Cashback List"}</h1>

        {HasAnyAccess(["cashback.master.create"]) && (
          <div>
            <button
              className="text-white  bg-purple-950 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none  ease-linear transition-all duration-150 rounded-md mr-2"
              type="button"
              onClick={() => downloadSample()}
            >
              Download Sample
            </button>

            <button
              className="text-white  bg-purple-950 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none  ease-linear transition-all duration-150 rounded-md mr-2"
              type="button"
              onClick={() => setOpenModal(true)}
            >
              Create Cashback
            </button>
          </div>
        )}
      </div>
      {/* {Loading ? (
        <Loader />
      ) : ( */}
      <div className="border bg-white rounded border-gray-200 shadow">
        {/* <div className="flex-1 p-2">
          <form
            onSubmit={(e) => {
              handleSubmit(0);
              e.preventDefault();
            }}
          >
            <div className="flex flex-wrap -mx-3 mb-2">
              <div className="pb-3 w-full md:w-1/3 px-3 mb-6 md:mb-0">
                <div className="relative">
                  <label
                    className="block text-sm font-medium text-gray-700"
                    htmlFor="description"
                  >
                    Partner
                  </label>
                  <select
                    name="partnerId"
                    className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    id="grid-state"
                    value={Filter.partnerId}
                    onChange={(e) => {
                      setPartnerId(e.target.value);
                      handleChangeFilter(e);
                    }}
                  >
                    <option disabled value="">
                      Select Partner
                    </option>
                    {partnerList.length > 0 &&
                      partnerList?.map((partner: any) => (
                        <option
                          key={partner?.partnerId}
                          value={partner?.partnerId}
                        >
                          {partner?.name}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
              <div className="pb-3 w-full md:w-1/3 px-3 mb-6 md:mb-0">
                <div className="relative">
                  <label
                    className="block text-sm font-medium text-gray-700"
                    htmlFor="description"
                  >
                    B2B Customer
                  </label>
                  <select
                    name="b2bSenderId"
                    className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    id="grid-state"
                    value={Filter.b2bSenderId}
                    onChange={(e) => {
                      setB2BSenderId(e.target.value);
                      handleChangeFilter(e);
                    }}
                  >
                    <option disabled value="">
                      Select B2B Customer
                    </option>
                    {b2bCustomers.length > 0 &&
                      b2bCustomers?.map((b2bcustomer: any) => (
                        <option
                          key={b2bcustomer?.customerId}
                          value={b2bcustomer?.customerId}
                        >
                          {b2bcustomer?.displayName} ({b2bcustomer?.partnerName}
                          )
                        </option>
                      ))}
                  </select>
                </div>
              </div>
              <div className="pb-3 w-full md:w-1/3 px-3 mb-6 md:mb-0">
                <label
                  className="block text-sm font-medium text-gray-700"
                  htmlFor="description"
                >
                  Metal type
                </label>

                <select
                  id="metalType"
                  name="metalType"
                  className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  onChange={(e: any) => {
                    handleChangeFilter(e);
                  }}
                  value={Filter.metalType}
                >
                  <option value="">All</option>
                  <option value="amount">Amount</option>
                  <option value="quantity">Quantity</option>
                </select>
              </div>
              <div className="pb-3 w-full md:w-1/3 px-3 mb-6 md:mb-0">
                <label
                  className="block text-sm font-medium text-gray-700"
                  htmlFor="grid-state"
                >
                  Metal
                </label>

                <select
                  id="metal"
                  name="metal"
                  className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  onChange={(e: any) => {
                    handleChangeFilter(e);
                  }}
                  value={Filter.metal}
                >
                  <option value="">All</option>
                  <option value="Silver">Silver</option>
                  <option value="Platinum">Platinum</option>
                  <option value="Palladium">Palladium</option>
                  <option value="Gold">Gold</option>
                </select>
              </div>
              <div className="pb-3 w-full md:w-1/3 px-3 mb-6 md:mb-0">
                <label
                  className="block text-sm font-medium text-gray-700"
                  htmlFor="grid-state"
                >
                  From date
                </label>

                <Datetime
                  timeFormat={""}
                  dateFormat={"DD/MM/YYYY"}
                  inputProps={{
                    readOnly: true,
                    placeholder: "pick date",
                    className: "focus:outline-none",
                  }}
                  className={`rounded-md shadow-sm appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-4`}
                  onChange={(event: any) => {
                    setFilter({
                      ...Filter,
                      startDate: moment(new Date(event).toISOString()).format(
                        "DD/MM/YYYY"
                      ),
                      endDate: moment(new Date(event).toISOString()).format(
                        "DD/MM/YYYY"
                      ),
                    });
                    setStrtDate(new Date(event));
                  }}
                  isValidDate={disableFutureStartDt}
                  value={moment(Filter.startDate)}
                  renderInput={(props) => {
                    return (
                      <input
                        {...props}
                        value={Filter.startDate ? Filter.startDate : ""}
                      />
                    );
                  }}
                />
              </div>
              <div className="pb-3 w-full md:w-1/3 px-3 mb-6 md:mb-0">
                <label
                  className="block text-sm font-medium text-gray-700"
                  htmlFor="grid-state"
                >
                  To date
                </label>
                <Datetime
                  timeFormat={""}
                  dateFormat={"DD/MM/YYYY"}
                  inputProps={{
                    readOnly: true,
                    placeholder: "pick date",
                    className: "focus:outline-none",
                  }}
                  isValidDate={disableFutureEndDt}
                  className={`rounded-md shadow-sm appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-4`}
                  onChange={(event: any) =>
                    setFilter({
                      ...Filter,
                      endDate: moment(new Date(event).toISOString()).format(
                        "DD/MM/YYYY"
                      ),
                    })
                  }
                  value={moment(Filter.endDate)}
                  renderInput={(props) => {
                    return (
                      <input
                        {...props}
                        value={Filter.endDate ? Filter.endDate : ""}
                      />
                    );
                  }}
                />
              </div>
              <div className="flex w-full justify-end px-3 mb-6 md:mb-0">
                <div className="my-3">
                  <input
                    className="text-white bg-purple-950 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none  ease-linear transition-all duration-150 rounded-md mr-2"
                    type="submit"
                    value="Filter"
                    onClick={() => handleSubmit(CurrentPage)}
                  />

                  <button
                    className="text-white rounded-md bg-red-600 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none  ease-linear transition-all duration-150"
                    type="button"
                    onClick={(e) => resetData(e)}
                  >
                    Reset
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div> */}
        <div className="bg-white rounded border-gray-200 shadow m-2">
          {/* {Loading ? (
            <Loader />
          ) : ( */}
          <div>
            <div className="overflow-x-auto">
              <table className="whitespace-nowrap  rounded w-full mx-auto bg-gray-800 text-gray-200 text-xs ">
                <thead>
                  <tr className="text-left border-b border-gray-300">
                    <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                      Id
                    </th>
                    <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                      Title
                    </th>
                    <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                      Created At (Date)
                    </th>
                    <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                      Total users
                    </th>
                    {/* <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                            Success users
                          </th> */}

                    {HasAnyAccess(["cashback.master.view"]) && (
                      <th className="sticky top-0 px-3 py-2 bg-gray-800">
                        Actions
                      </th>
                    )}
                  </tr>
                </thead>

                <tbody>
                  {giftlist?.length > 0 ? (
                    giftlist?.map((b2b: any, index: number) => {
                      return (
                        <tr
                          key={index}
                          className="border-b border-gray-200 bg-white text-black  hover:bg-gray-100"
                        >
                          <td className="py-3 px-3 text-left relative">
                            {b2b.cashbackId}
                          </td>
                          <td className="py-3 px-3 text-left relative">
                            {b2b.reason}
                          </td>
                          <td className="py-3 px-3 text-left relative">
                            {moment(b2b.createdAt).format("DD-MM-YYYY")}
                          </td>
                          <td className="py-3 px-3 text-left relative">
                            {b2b.userCount}
                          </td>
                          
                          {HasAnyAccess(["cashback.master.view"]) && (
                            <td className="py-3 px-3 text-left relative">
                              <button
                                className="text-purple-900 border border-purple-300 rounded-md bg-purple-100 background-transparent font-bold uppercase px-3 py-1 text-sm outline-none focus:outline-none mr-2  ease-linear transition-all duration-150"
                                type="button"
                                onClick={() => viewFile(b2b.cashbackId)}
                                // disabled={!order.isCompleted}
                              >
                                {"View"}
                              </button>
                            </td>
                          )}
                        </tr>
                      );
                    })
                  ) : (
                    <tr className="border-b border-gray-200 bg-white text-black hover:bg-gray-100">
                      <td
                        colSpan={10}
                        className="text-center py-3 px-3 text-left relative"
                      >
                        No data to display
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <Pagination
              meta={meta}
              handlePageChange={(number: number) => handleSubmit(number)}
            />
          </div>
          {/* )} */}
        </div>
      </div>
      {/* )} */}
      {openModal && (
        <CreateCashback onCloseModal={closeModal} giftList={handleSubmit} />
      )}
    </Layout>
  );
};

export default CashbackList;
