import React, { useEffect, useState } from "react";
import Layout from "../Layout/Layout";
import BulkUploadService from "../BulkUpload/BulUploadService";
import { useAuthStore } from "../../Store/AuthStore";
import Loader from "../../Components-ui/Loader";
import SimpleReactValidator from "simple-react-validator";
import Service from "../CustomerGroup/CustomerGroup.Service";
import { ToastContainer, toast } from "react-toastify";
import CashbackService from "./Cashback.service";
import CustomerService from "../Customer/Services/Customer.service";
import { useHistory } from "react-router-dom";

interface PropsType {
  onCloseModal: any;
  giftList: any;
}

const CreateCashback: React.FC<PropsType> = ({ onCloseModal, giftList }) => {
  const [Loading, setLoading] = useState<any>(false);

  const [message, setMessage] = useState("");
  const [file, setFile] = useState("");

  const [validatorStatus, setValidator] = useState<boolean>(false);
  const [fileToUpload, setFileToUpload] = useState("");

  const { logout, permissions, userData, tokens } = useAuthStore();

 
  const simpleValidator = new SimpleReactValidator({
    locale: "en",
    validators: {},
  });
  const validationRef = React.useRef(simpleValidator);
  const validator = validationRef.current;

  const history = useHistory();
  const changeHandler = (event: any) => {
    let selectedFile = event.target.files[0];
    setFileToUpload(selectedFile);
    let reader = new FileReader();
    reader.readAsDataURL(selectedFile);
    reader.onloadend = (e: any) => {
      setFile(e.target.result);
    };
  };

  const submitForm = async () => {
    setValidator(true);

    if (validator.allValid()) {
      let formData = new FormData();

      formData.append("reason", message);
      formData.append("file", fileToUpload);

      setValidator(true);
      setLoading(true);

      await CashbackService.createCashback(tokens, formData)
        .then((response: any) => {
          setLoading(false);
          toast.success(response.data.data.payload.data.msg);
          onCloseModal();
          // window.location.href = window.location.href;
          giftList(0);
        })
        .catch((err: any) => {
          toast.error(err?.response?.data?.errors[0]?.title);
          setLoading(false);
          onCloseModal();
        });
    } else {
      validator.showMessages();

      // rerender to show messages for the first time
      // you can use the autoForceUpdate option to do this automatically`
      // forceUpdate();
    }
  };

  const getValidatorError = (nameOfStateProp: any) => {
    const allErrorMessages = validator.getErrorMessages();
    return allErrorMessages[nameOfStateProp];
  };

  return (
    <>
      {Loading ? (
        <Loader />
      ) : (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-50">
          <div className="relative w-full max-w-4xl bg-white rounded-lg shadow-lg">
            {/* Header */}
            <div className="px-6 py-4 border-b bg-gray-100 rounded-t flex justify-between items-center">
              <h1 className="text-2xl font-bold">Create Cashback</h1>
              <button
                className="text-gray-600 hover:text-gray-900"
                onClick={onCloseModal}
              >
                ✕
              </button>
            </div>

            {/* Body */}
            <div className="p-6">
              <div className="bg-white border border-gray-200 rounded shadow">
                <div className="p-4">
                  <fieldset className="space-y-4">
                    {/* Message Input */}
                    <div>
                      <label className="block text-sm font-medium text-gray-700">
                        Title
                      </label>
                      <input
                        className="w-full mt-1 p-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        name="message"
                        type="text"
                        placeholder="Enter Title"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                      />
                      <span className="text-xs text-red-700">
                        {validator.message("Title", message, "required")}
                      </span>
                    </div>

                    {/* File Upload */}
                    <div>
                      <label className="block text-sm font-medium text-gray-700">
                        Upload File
                      </label>
                      <input
                        className="w-full mt-1 p-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        type="file"
                        // accept=".xls,.xlsx"
                        onChange={changeHandler}
                      />
                      <span className="text-xs text-red-700">
                        {validator.message("Document", file, "required")}
                      </span>
                      <ul className="mt-2 text-xs text-green-600">
                        <li>File size should not exceed 15 MB</li>
                        {/* <li>Only .xlsx files are allowed</li> */}
                      </ul>
                    </div>
                  </fieldset>
                </div>
              </div>
            </div>

            {/* Footer */}
            <div className="flex justify-end gap-2 px-6 py-4 border-t bg-gray-100 rounded-b">
              <button
                className="px-6 py-2 text-sm font-bold text-purple-900 bg-purple-100 border border-purple-300 rounded-md"
                onClick={onCloseModal}
              >
                Close
              </button>
              <button
                className="px-6 py-2 text-sm font-bold text-white bg-purple-950 rounded-md"
                onClick={submitForm}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CreateCashback;
